import React, { useState, useEffect } from "react";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { ShowRoom } from "./showroom";
// import { ShowroomFeature } from "./showroomfeature";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import "../App.css";

export const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Team data={landingPageData.Team} />
      <Services data={landingPageData.Services} />
      <ShowRoom data={landingPageData.ShowRoom} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
